<div *ngIf="totalRecord > 0">
    <ul class="pagination justify-content-center mt-4">
        <li class="page-item" (click)="currentPage > 1 && getPagedRecords('doubleLeft')" [ngClass]="{'disabled':currentPage === 1}">
            <a class="page-link" tabindex="-1"><i class="fa fa-angle-double-left"></i></a>
        </li>
        <li class="page-item" (click)="currentPage > 1 && getPagedRecords('prev')" [ngClass]="{'disabled':currentPage === 1}">
            <a class="page-link" tabindex="-1">Prev</a>
        </li>
        <li class="page-item" *ngFor="let page of pagination.pages" [ngClass]="{'active': page === currentPage}">
            <a class="page-link" (click)="currentPage !== page && getPagedRecords('specific', page)">{{ page }}</a>
        </li>
        <li class="page-item" (click)="currentPage !== pagination.totalPages && getPagedRecords('next')"
            [ngClass]="{'disabled':currentPage === pagination.totalPages}">
            <a class="page-link">Next</a>
        </li>
        <li class="page-item" (click)="currentPage !== pagination.totalPages && getPagedRecords('doubleRight')"
            [ngClass]="{'disabled':currentPage === pagination.totalPages}">
            <a class="page-link"><i class="fa fa-angle-double-right"></i></a>
        </li>
    </ul>
</div>