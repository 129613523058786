<div class="app" [hidden]="!isShowAsideBar">

    <!-- Desktop Header -->
    <header class="app-header app-header-dark">
        <div class="top-bar">
            <div class="top-bar-brand">

                <!-- Desktop Menu Toggle Button-->
                <button class="hamburger hamburger-squeeze mr-2" type="button" data-toggle="aside-menu"
                    aria-label="toggle aside menu"><span class="hamburger-box"><span
                            class="hamburger-inner"></span></span>
                </button> 
            </div>

            <div class="top-bar-list">
                
                <!-- Mobile Menu Toggle Button-->
                <div class="top-bar-item px-2 d-md-none d-lg-none d-xl-none">
                    <button class="hamburger hamburger-squeeze" type="button" data-toggle="aside" aria-label="toggle menu">
                        <span class="hamburger-box"><span class="hamburger-inner"></span></span>
                    </button> 
                </div>
               
                <div class="top-bar-item top-bar-item-full" *ngIf="isShowHeaderSearch">
                    <form class="top-bar-search">
                        <div class="input-group input-group-search dropdown">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><span class="oi oi-magnifying-glass"></span></span>
                            </div><input type="text" class="form-control" data-toggle="dropdown" aria-label="Search"
                                placeholder="Search">
                            <div class="dropdown-menu dropdown-menu-rich dropdown-menu-xl ml-n4 mw-100">
                                <div class="dropdown-arrow ml-3"></div>
                                <div class="dropdown-scroll perfect-scrollbar h-auto" style="max-height: 360px">

                                    <div class="list-group list-group-flush list-group-reflow mb-2">
                                        <h6 class="list-group-header d-flex justify-content-between">
                                            <span>Shortcut</span>
                                        </h6>
                                        <div class="list-group-item py-2">
                                            <a href="#" class="stretched-link"></a>
                                            <div class="tile tile-sm bg-muted">
                                                <i class="fas fa-user-plus"></i>
                                            </div>
                                            <div class="ml-2"> Create user </div>
                                        </div>

                                        <div class="list-group-item py-2">
                                            <a href="#" class="stretched-link"></a>
                                            <div class="tile tile-sm bg-muted">
                                                <i class="fas fa-dollar-sign"></i>
                                            </div>
                                            <div class="ml-2"> Create invoice </div>
                                        </div>

                                        <h6 class="list-group-header d-flex justify-content-between mt-2">
                                            <span>In projects</span> <a href="#" class="font-weight-normal">Show
                                                more</a>
                                        </h6>
                                        <div class="list-group-item py-2">
                                            <a href="#" class="stretched-link"><span class="sr-only">Go to search
                                                    result</span></a>
                                            <div class="user-avatar user-avatar-sm bg-muted">
                                                <img src="assets/images/avatars/bootstrap.svg" alt="">
                                            </div>
                                            <div class="ml-2">
                                                <div class="mb-n1"> Bootstrap </div><small class="text-muted">Just
                                                    now</small>
                                            </div>
                                        </div>

                                        <div class="list-group-item py-2">
                                            <a href="#" class="stretched-link"><span class="sr-only">Go to search
                                                    result</span></a>
                                            <div class="user-avatar user-avatar-sm bg-muted">
                                                <img src="assets/images/avatars/slack.svg" alt="">
                                            </div>
                                            <div class="ml-2">
                                                <div class="mb-n1"> Slack </div><small class="text-muted">Updated 25
                                                    minutes ago</small>
                                            </div>
                                        </div>

                                        <h6 class="list-group-header d-flex justify-content-between mt-2">
                                            <span>Another section</span> <a href="#" class="font-weight-normal">Show
                                                more</a>
                                        </h6>
                                        <div class="list-group-item py-2">
                                            <a href="#" class="stretched-link"><span class="sr-only">Go to search
                                                    result</span></a>
                                            <div class="tile tile-sm bg-muted"> P </div>
                                            <div class="ml-2">
                                                <div class="mb-n1"> Product name </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a href="#" class="dropdown-footer">Show all results</a>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="top-bar-item top-bar-item-right px-0 d-none d-sm-flex">
                    <ul class="header-nav nav">

                        <li class="nav-item dropdown header-nav-dropdown has-notified" *ngIf="isShowHeaderActivities">
                            <a class="nav-link" href="#" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false"><span class="oi oi-pulse"></span></a> <!-- .dropdown-menu -->
                            <div class="dropdown-menu dropdown-menu-rich dropdown-menu-right">
                                <div class="dropdown-arrow"></div>
                                <h6 class="dropdown-header stop-propagation">
                                    <span>Activities <span class="badge">(2)</span></span>
                                </h6>
                                <div class="dropdown-scroll perfect-scrollbar">

                                    <a href="#" class="dropdown-item unread">
                                        <div class="user-avatar">
                                            <img src="assets/images/avatars/uifaces15.jpg" alt="">
                                        </div>
                                        <div class="dropdown-item-body">
                                            <p class="text"> Jeffrey Wells created a schedule </p><span
                                                class="date">Just now</span>
                                        </div>
                                    </a>

                                    <a href="#" class="dropdown-item unread">
                                        <div class="user-avatar">
                                            <img src="assets/images/avatars/uifaces16.jpg" alt="">
                                        </div>
                                        <div class="dropdown-item-body">
                                            <p class="text"> Anna Vargas logged a chat </p><span class="date">3 hours
                                                ago</span>
                                        </div>
                                    </a>

                                    <a href="#" class="dropdown-item">
                                        <div class="user-avatar">
                                            <img src="assets/images/avatars/uifaces17.jpg" alt="">
                                        </div>
                                        <div class="dropdown-item-body">
                                            <p class="text"> Sara Carr invited to Stilearn Admin </p><span
                                                class="date">5 hours ago</span>
                                        </div>
                                    </a>

                                    <a href="#" class="dropdown-item">
                                        <div class="user-avatar">
                                            <img src="assets/images/avatars/uifaces18.jpg" alt="">
                                        </div>
                                        <div class="dropdown-item-body">
                                            <p class="text"> Arthur Carroll updated a project </p><span class="date">1
                                                day ago</span>
                                        </div>
                                    </a>

                                    <a href="#" class="dropdown-item">
                                        <div class="user-avatar">
                                            <img src="assets/images/avatars/uifaces19.jpg" alt="">
                                        </div>
                                        <div class="dropdown-item-body">
                                            <p class="text"> Hannah Romero created a task </p><span class="date">1 day
                                                ago</span>
                                        </div>
                                    </a>

                                    <a href="#" class="dropdown-item">
                                        <div class="user-avatar">
                                            <img src="assets/images/avatars/uifaces20.jpg" alt="">
                                        </div>
                                        <div class="dropdown-item-body">
                                            <p class="text"> Angela Peterson assign a task to you </p><span
                                                class="date">2 days ago</span>
                                        </div>
                                    </a>

                                    <a href="#" class="dropdown-item">
                                        <div class="user-avatar">
                                            <img src="assets/images/avatars/uifaces21.jpg" alt="">
                                        </div>
                                        <div class="dropdown-item-body">
                                            <p class="text"> Shirley Mason and 3 others followed you </p><span
                                                class="date">2 days ago</span>
                                        </div>
                                    </a>
                                </div>
                                <a href="user-activities.html" class="dropdown-footer">All activities <i
                                        class="fas fa-fw fa-long-arrow-alt-right"></i></a>
                            </div>
                        </li>

                        <li class="nav-item dropdown header-nav-dropdown has-notified"
                            *ngIf="isShowHeaderNotifications">
                            <a class="nav-link" href="#" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false"><span class="oi oi-envelope-open"></span></a>
                            <!-- .dropdown-menu -->
                            <div class="dropdown-menu dropdown-menu-rich dropdown-menu-right">
                                <div class="dropdown-arrow"></div>
                                <h6 class="dropdown-header stop-propagation">
                                    <span>Messages</span> <a href="#">Mark all as read</a>
                                </h6>
                                <div class="dropdown-scroll perfect-scrollbar">

                                    <a href="#" class="dropdown-item unread">
                                        <div class="user-avatar">
                                            <img src="assets/images/avatars/team1.jpg" alt="">
                                        </div>
                                        <div class="dropdown-item-body">
                                            <p class="subject"> Stilearning </p>
                                            <p class="text text-truncate"> Invitation: Joe's Dinner @ Fri Aug 22 </p>
                                            <span class="date">2 hours ago</span>
                                        </div>
                                    </a>

                                    <a href="#" class="dropdown-item">
                                        <div class="user-avatar">
                                            <img src="assets/images/avatars/team3.png" alt="">
                                        </div>
                                        <div class="dropdown-item-body">
                                            <p class="subject"> Openlane </p>
                                            <p class="text text-truncate"> Final reminder: Upgrade to Pro </p><span
                                                class="date">23 hours ago</span>
                                        </div>
                                    </a>

                                    <a href="#" class="dropdown-item">
                                        <div class="tile tile-circle bg-green"> GZ </div>
                                        <div class="dropdown-item-body">
                                            <p class="subject"> Gogo Zoom </p>
                                            <p class="text text-truncate"> Live healthy with this wireless sensor. </p>
                                            <span class="date">1 day ago</span>
                                        </div>
                                    </a>

                                    <a href="#" class="dropdown-item">
                                        <div class="tile tile-circle bg-teal"> GD </div>
                                        <div class="dropdown-item-body">
                                            <p class="subject"> Gold Dex </p>
                                            <p class="text text-truncate"> Invitation: Design Review @ Mon Jul 7 </p>
                                            <span class="date">1 day ago</span>
                                        </div>
                                    </a>

                                    <a href="#" class="dropdown-item">
                                        <div class="user-avatar">
                                            <img src="assets/images/avatars/team2.png" alt="">
                                        </div>
                                        <div class="dropdown-item-body">
                                            <p class="subject"> Creative Division </p>
                                            <p class="text text-truncate"> Need some feedback on this please </p><span
                                                class="date">2 days ago</span>
                                        </div>
                                    </a>

                                    <a href="#" class="dropdown-item">
                                        <div class="tile tile-circle bg-pink"> LD </div>
                                        <div class="dropdown-item-body">
                                            <p class="subject"> Lab Drill </p>
                                            <p class="text text-truncate"> Our UX exercise is ready </p><span
                                                class="date">6 days ago</span>
                                        </div>
                                    </a>
                                </div>
                                <a href="page-messages.html" class="dropdown-footer">All messages <i
                                        class="fas fa-fw fa-long-arrow-alt-right"></i></a>
                            </div>
                        </li>

                        <li class="nav-item dropdown header-nav-dropdown" *ngIf="isShowHeaderDropdown">
                            <a class="nav-link" href="#" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false"><span class="oi oi-grid-three-up"></span></a>
                            <!-- .dropdown-menu -->
                            <div class="dropdown-menu dropdown-menu-rich dropdown-menu-right">
                                <div class="dropdown-arrow"></div><!-- .dropdown-sheets -->
                                <div class="dropdown-sheets">
                                    <!-- .dropdown-sheet-item -->
                                    <div class="dropdown-sheet-item">
                                        <a href="#" class="tile-wrapper"><span class="tile tile-lg bg-indigo"><i
                                                    class="oi oi-people"></i></span> <span
                                                class="tile-peek">Teams</span></a>
                                    </div><!-- /.dropdown-sheet-item -->
                                    <!-- .dropdown-sheet-item -->
                                    <div class="dropdown-sheet-item">
                                        <a href="#" class="tile-wrapper"><span class="tile tile-lg bg-teal"><i
                                                    class="oi oi-fork"></i></span> <span
                                                class="tile-peek">Projects</span></a>
                                    </div><!-- /.dropdown-sheet-item -->
                                    <!-- .dropdown-sheet-item -->
                                    <div class="dropdown-sheet-item">
                                        <a href="#" class="tile-wrapper"><span class="tile tile-lg bg-pink"><i
                                                    class="fa fa-tasks"></i></span> <span
                                                class="tile-peek">Tasks</span></a>
                                    </div><!-- /.dropdown-sheet-item -->
                                    <!-- .dropdown-sheet-item -->
                                    <div class="dropdown-sheet-item">
                                        <a href="#" class="tile-wrapper"><span class="tile tile-lg bg-yellow"><i
                                                    class="oi oi-fire"></i></span> <span
                                                class="tile-peek">Feeds</span></a>
                                    </div><!-- /.dropdown-sheet-item -->
                                    <!-- .dropdown-sheet-item -->
                                    <div class="dropdown-sheet-item">
                                        <a href="#" class="tile-wrapper"><span class="tile tile-lg bg-cyan"><i
                                                    class="oi oi-document"></i></span> <span
                                                class="tile-peek">Files</span></a>
                                    </div><!-- /.dropdown-sheet-item -->
                                </div><!-- .dropdown-sheets -->
                            </div><!-- .dropdown-menu -->
                        </li>
                    </ul>

                    <!-- Desktop Profile Button -->
                    <div class="dropdown d-flex">
                        <button class="btn-account d-none d-md-flex" type="button" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false"><span class="user-avatar user-avatar-md"><img
                                    [src]="getImage()" alt=""></span> 
                                    <span class="account-summary pr-lg-4 d-none d-lg-block" *ngIf="currentUser">
                                <span class="account-name">{{currentUser.firstname | titlecase}} {{currentUser.lastname | titlecase}} </span> 
                                <span class="account-description"> {{currentUser.role | titlecase}} </span>
                            </span>
                                </button> 

                        <!-- Profile Dropdown -->
                        <div class="dropdown-menu" *ngIf="isShowHeaderAcountMenu">
                            <div class="dropdown-arrow ml-3"></div>
                            <h6 class="dropdown-header d-none d-md-block d-lg-none" *ngIf="currentUser"> {{currentUser.firstname | titlecase}} {{currentUser.lastname | titlecase}} </h6>
                            
                            <a class="dropdown-item" (click)="redirectTo('profile')">
                                <span class="dropdown-icon oi oi-person"></span> Profile
                            </a>
                            <a class="dropdown-item" (click)="redirectTo('change-password')">
                                <span class="dropdown-icon oi oi-key"></span> Change Password
                            </a>
                            <a class="dropdown-item" [routerLink]="['/login']" [queryParams]="{logout: true}">
                                <span class="dropdown-icon oi oi-account-logout"></span> Logout
                            </a>
                            
                            <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="https://www.technovez.com/About"> <span class="dropdown-icon oi oi-info"></span> About</a> 
                        <a class="dropdown-item" href="https://www.technovez.com/"><span class="dropdown-icon oi oi-globe"></span>Technovez</a> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>


<!-- Body must be here -->
<!-- <router-outlet></router-outlet> -->

    <!-- Aside -->
    <aside class="app-aside app-aside-expand-md app-aside-light"> 
        <div class="aside-content">
            
            <!-- Mobile-Header -->
            <header class="aside-header d-block d-md-none">

                <!-- Profile-button -->
                <button class="btn-account" type="button" data-toggle="collapse" data-target="#dropdown-aside">
                    <span class="user-avatar user-avatar-lg"><img src="assets/images/avatars/profile.jpg" alt=""></span>
                    <span class="account-icon"><span class="fa fa-caret-down fa-lg"></span></span> 
                    <span class="account-summary" *ngIf="currentUser">
                    <span class="account-name"> {{currentUser.firstname | titlecase}} {{currentUser.lastname | titlecase}} </span> 
                    <span class="account-description"> {{currentUser.role | titlecase}} </span>
                </span>
                </button> 
             
                <!-- Profile Dropdown -->
                <div id="dropdown-aside" class="dropdown-aside collapse">
                    <div class="pb-3">
                        <a class="dropdown-item" (click)="redirectTo('profile')"><span class="dropdown-icon oi oi-person"></span> Profile</a> 
                        <a class="dropdown-item" (click)="redirectTo('change-password')"><span class="dropdown-icon oi oi-key"></span> Change Password</a>
                        <a class="dropdown-item" [routerLink]="['/login']" [queryParams]="{logout: true}"><span class="dropdown-icon oi oi-account-logout"></span> Logout</a>

                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="https://www.technovez.com/About"> <span class="dropdown-icon oi oi-info"></span> About</a> 
                        <a class="dropdown-item" href="https://www.technovez.com/"><span class="dropdown-icon oi oi-globe"></span>Technovez</a> 
                    </div>
                </div>
            </header>

            <!-- Aside-menu -->
            <!-- <div class="aside-menu overflow-hidden">
                <nav id="stacked-menu" class="stacked-menu">
                    <ul class="menu">
                        <li class="menu-item" *ngFor="let menuItem of menuList" [ngClass]="{'has-active': menuItem.url === currentPage, 'has-child': menuItem.subMenus && menuItem.subMenus.length}">
                            <a (click)="redirectTo(menuItem)" class="menu-link">
                                <span class="menu-icon {{menuItem.iconClass}}"></span> 
                                <span class="menu-text"> {{menuItem.text}} </span>
                            </a>
                                <ul class="menu" *ngIf="menuItem.subMenus && menuItem.subMenus.length">
                                    <li class="menu-item" *ngFor="let subMenuItem of menuItem.subMenus" [ngClass]="{'has-active': subMenuItem.url === currentPage}">
                                        <a *ngIf="menuItem.subMenus && menuItem.subMenus.length" (click)="redirectTo(subMenuItem)" class="menu-link"> 
                                            <span class="menu-icon {{subMenuItem.iconClass}}"></span> 
                                            <span class="menu-text"> {{subMenuItem.text}} </span>
                                        </a>
                                    </li>
                                </ul>
                        </li>
                    </ul>
                </nav>
            </div> -->
            
            <!-- Todo: must be proper dynamic with reload window. -->
            <div class="aside-menu overflow-hidden">
                <nav id="stacked-menu" class="stacked-menu">
                    <ul class="menu">
                        <li class="menu-item" *ngFor="let menuItem of menuItems" [ngClass]="{'has-active': menuItem.url === currentPage, 'has-child': menuItem.subMenus && menuItem.subMenus.length}">
                            <a (click)="redirectTo(menuItem.url)" class="menu-link">
                                <span class="menu-icon {{menuItem.iconClass}}"></span> 
                                <span class="menu-text"> {{menuItem.text}} </span>
                            </a>
                                <ul class="menu" *ngIf="menuItem.subMenus && menuItem.subMenus.length">
                                    <li class="menu-item" *ngFor="let subMenuItem of menuItem.subMenus" [ngClass]="{'has-active': subMenuItem.url === currentPage}">
                                        <a *ngIf="menuItem.subMenus && menuItem.subMenus.length" (click)="redirectTo(subMenuItem.url)" class="menu-link"> 
                                            <span class="menu-icon {{subMenuItem.iconClass}}"></span> 
                                            <span class="menu-text"> {{subMenuItem.text}} </span>
                                        </a>
                                    </li>
                                </ul>
                        </li>
                    </ul>
                </nav>
            </div>

            <!-- Todo: menu items should be dynamic, submenu items were creating issues when menu list changed for another user -->
         <!-- <div class="aside-menu overflow-hidden">
            <nav id="stacked-menu" class="stacked-menu">
              <ul class="menu">
                <li class="menu-item has-active" [hidden]="menuId !== 'adminMenu'">
                  <a href="index.html" class="menu-link"><span class="menu-icon fas fa-home"></span> <span class="menu-text">Dashboard</span></a>
                </li>
                <li class="menu-item has-active" [hidden]="menuId !== 'employeeMenu'">
                  <a href="index.html" class="menu-link"><span class="menu-icon fas fa-home"></span> <span class="menu-text">Dashboard</span></a>
                </li>

                <li class="menu-item has-child">
                  <a href="#" class="menu-link"><span class="menu-icon far fa-file"></span> <span class="menu-text">App Pages</span> <span class="badge badge-warning">New</span></a> 
                  
                  <ul class="menu" >
                    <li class="menu-item" [hidden]="menuId !== 'adminMenu'">
                      <a href="page-clients.html" class="menu-link">Employees</a>
                    </li>
                
                    <li class="menu-item" [hidden]="menuId !== 'employeeMenu'">
                      <a href="page-clients.html" class="menu-link"> History </a>
                    </li>
                    <li class="menu-item" [hidden]="menuId !== 'employeeMenu'">
                      <a href="page-teams.html" class="menu-link"> Leave Request </a>
                    </li> 
                  </ul>
                </li>
              </ul>

            </nav>
          </div> -->
          
            
            <!-- Aside Footer -->
            <footer class="aside-footer border-top p-2">
                <button class="btn btn-light btn-block text-primary" data-toggle="skin" (click)="toggleThemeColor()">
                    <span class="d-compact-menu-none">Night mode</span> <i class="fas fa-moon ml-1"></i>
                </button>
            </footer>
        </div>
    </aside>
    
</div>